<template>
<div class="dashboard-top-header" v-if="isDashboard">
    <div class="dashboard-top-header-container">
        <div class="logo">
            <img src="../../assets/logo.svg" alt="">
            <div class="name">OTCPro</div>
        </div>
        <div class="user" :class="{show: showProfileMenu}" v-if="userInfo" @click="onDashBoardClick()">
            <div class="my-user-card">
                <MenuOutlined v-if="!showProfileMenu" />
                <CloseOutlined v-else />
                <div class="initial">{{userInfo.initial}}</div>
                <div class="name">
                    <div class="display-name">{{userInfo.display_name}}</div>
                    <div class="email" v-if="userInfo.display_name!=userInfo.email">{{userInfo.email}}</div>
                </div>
                <BellFilled class="notification" v-if="showNotificationIcon" />
            </div>
            <transition name="fade">
                <div class="profile-menu" v-if="showProfileMenu&&!showMobileMenu">
                    <div class="profile-menu-user-card-container">
                        <div class="my-user-card" >
                            <div class="initial">{{userInfo.initial}}</div>
                            <div class="name">
                                <div class="display-name">{{userInfo.display_name}}</div>
                                <div class="email" v-if="userInfo.display_name!=userInfo.email">{{userInfo.email}}</div>
                            </div>
                            <BellFilled class="notification" v-if="showNotificationIcon" />
                        </div>
                    </div>
                    <div class="profile-menu-items">
                        <div class="profile-menu-item" v-if="currentPath=='/'" @click="() => { router.push({ path: `/dashboard` }) }">
                            <div>Dashboard</div>
                        </div>
                        <div class="profile-menu-item" @click="onProfileClick">
                            <div>My Profile</div>
                            <div class="warning" v-if="!userInfo.identification_type || userInfo.identification_status == 'PENDING'">
                                <span>Identify not verified!</span>
                            </div>
                        </div>
                        <div class="profile-menu-item" v-if="userInfo" @click="logout">
                            <div>Logout</div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
    <transition name="slide-down" >
        <div class="mobile-nav" v-if="showProfileMenu">
            <div class="user" v-if="userInfo" @click="onDashBoardClick()">
                <div class="my-user-card" >
                    <div class="initial">{{userInfo.initial}}</div>
                    <div class="name">
                        <div class="display-name">{{userInfo.display_name}}</div>
                        <div class="email">{{userInfo.email}}</div>
                    </div>
                </div>
            </div>
            <a-menu class="mobile-nav-menu" v-model:selectedKeys="selectedKeys" :default-selected-keys="['DASHOARD']" :openKeys="openKeys" mode="inline">
                <a-menu-item title="Dashboard" @click="onItemClick('/dashboard')" v-if="currentPath=='/' && userInfo">Dashboard</a-menu-item>
                <!-- <a-menu-item v-for="menu in menus" :key="menu.path" :title="menu.name" @click="onItemClick(menu.path)">{{menu.name}}</a-menu-item> -->
                <template v-for="menu in menus">
                    <a-sub-menu :key="menu.key+'1'" v-if="menu.sub_menus.length>0" class="side-nav-menu" @titleClick="onItemClick(menu.path||'/kyc')">
                        <template #title ><div>{{menu.title}}</div></template>
                        <a-menu-item  v-for="sub in menu.sub_menus" :key="sub.key" @click="onItemClick(sub.path||'/kyc')" :disabled="!sub.path">{{sub.title}}</a-menu-item>
                    </a-sub-menu>
                    <a-menu-item v-else :key="menu.key" class="side-nav-menu" @click="onItemClick(menu.path||'/kyc')" :disabled="!menu.path" >
                        <!-- <template #icon>
                            <component :is="menu.icon" />
                        </template> -->
                        {{menu.title}}
                        
                    </a-menu-item>
                </template>
            </a-menu>
            <a-menu
                class="mobile-nav-menu"
                v-if="userInfo"
                >
                <a-popconfirm
                    title="Are you sure to logout?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="logout"
                >
                    <a-menu-item class="side-nav-menu logout" >
                        <template #icon>
                            <LogoutOutlined />
                        </template>
                        <span>{{ i18n('global.menu.btn_sign_off') }}</span>
                    </a-menu-item>
                </a-popconfirm>
            </a-menu>
            <div class="user" v-if="!userInfo">
                <a-button type="link" size="large" @click="onSignUpClick">>{{ i18n('global.menu.btn_sign_up') }}</a-button>
                <a-button type="primary" size="large" @click="onSignInClick">{{ i18n('global.menu.btn_sign_in') }}</a-button>
            </div>
        </div>
    </transition>
</div>
</template>

<script setup lang="ts">
import { h, ref, reactive, computed, watch, onMounted } from "vue";
import { MenuOutlined, BellFilled, CloseOutlined, WalletOutlined, UserOutlined, BookOutlined, SwapOutlined, DownloadOutlined, UploadOutlined, LogoutOutlined } from "@ant-design/icons-vue";
import { useStore } from "../../store" ;
import { useRoute, useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'
const { t:i18n } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();
const showMobileMenu = ref<Boolean>(false);
const showProfileMenu = ref<Boolean>(false);

// const selectedKeys = ref([]);
const userInfo = computed(() => store.getters.userInfo );

const menus = computed(() => {
    if(userInfo.value && userInfo.value.identification_status == 'VERIFIED') {
        let m = [
            { key: 'DASHOARD', title: `Dashboard`, path: `/dashboard`, sub_menus:[] },
            { key: 'DEPOSIT', title: `Deposits`, path: `/dashboard/deposit`, sub_menus:[] },
            { key: 'WITHDRAW', title: `Withdrawals`, path: `/dashboard/withdraw`, sub_menus: [
                { key: 'WITHDRAW.RECENT', title: 'Make Withdrawals', path: `/dashboard/withdraw` },
                { key: 'WITHDRAW.TOKEN_ADDRESS', title: 'Manage Crypto Addresses', path: `/dashboard/withdraw/token` },
                { key: 'WITHDRAW.BANK_ACCOUNT', title: 'Manage Bank Accounts', path: `/dashboard/withdraw/bank` },
            ] },
            { key: 'TRADE', title: `Buy/Sell`, path: `/dashboard/trade`, sub_menus:[] },
            { key: 'HISTORY', title: `History`, path: `/dashboard/history`, sub_menus:[] },
            { key: 'REFERRAL', title: `Referral`, path: `/dashboard/referral`, sub_menus:[] },
            { key: 'SECURITY', title: `Security`, path: `/dashboard/security`, sub_menus:[
                { key: 'SECURITY.UPDATE_PWD', title: 'Update Password', path: `/dashboard/security` },
                { key: 'SECURITY.UPDATE_2FA', title: 'Update 2FA', path: `/dashboard/security/2fa` },
            ] },
            { key: 'SUPPORT', title: `Support`, path: `/dashboard/support`, sub_menus:[] },
        ];
        if(userInfo.value.dev_key_enabled == 1) {
            m[6].sub_menus.push({ key: 'SECURITY.UPDATE_DEV_API', title: 'Developer API', path: `/dashboard/security/developer` });
        }
        return m;
    }
    return [
        { key: 'KYC', title: `Complete Kyc`, path: `/dashboard/kyc`, sub_menus:[] },
        { key: 'DASHOARD', title: `Dashboard`, sub_menus:[] },
        { key: 'DEPOSIT', title: `Deposits`, sub_menus:[] },
        { key: 'WITHDRAW', title: `Withdrawals`, sub_menus:[]},
        { key: 'TRADE', title: `Buy/Sell`, sub_menus:[] },
        { key: 'REFERRAL', title: `Referral`, sub_menus:[] },
        { key: 'SECURITY', title: `Security`, path: `/dashboard/security`, sub_menus:[] },
        { key: 'SUPPORT', title: `Support`, path: `/dashboard/support`, sub_menus:[] },
    ]
})

const openKeys = computed(() => {
    if(selectedKeys.value.length > 0) {
        return [selectedKeys.value[0].split('.')[0] + '1'];
    }
    return []
});

watch(showProfileMenu, (status) => {
    store.commit('setMobileMenuOpenStatus', status);
});

const currentPath = computed(() => {
    return route.path;
});

const isDashboard = computed(() => {
    return currentPath.value.startsWith('/dashboard');
});

const onMenuClick = () => {
    showMobileMenu.value = !showMobileMenu.value;
}
const onItemClick = (path = '/') => {
    router.push({ path });
    // selectedKeys.value = [];
    showProfileMenu.value = false;
}
const onSignInClick = () => {
    router.push({ path: `/signin` });
    showProfileMenu.value = false;
}
const onSignUpClick = () => {
    router.push({ path: `/signup` });
    showProfileMenu.value = false;
}
const onDashBoardClick = () => {
    // router.push({ path: `/dashboard` });
    showMobileMenu.value = false;
    showProfileMenu.value = !showProfileMenu.value;
}
const onDashboardMenuClick = (path:string) => {
    router.push({ path });
    showProfileMenu.value = false;
}

// const selectedDashboardMenuKeys = computed(() => {
//     let key:Array<any> = [];
//     for(let i = 0; i < menus.value.length; i++ ) {
//         if(currentPath.value.includes(menus.value[i].path)) {
//             key = [menus.value[i].key]
//         }
//     }
//     return key;
// });

const selectedKeys = computed(() => {
    let key:Array<any> = [];
    for(let i = 0; i < menus.value.length; i++ ) {
        let path = menus.value[i].path;
        if(path && currentPath.value.includes(path)) {
            key = [menus.value[i].key]
        }
        if(menus.value[i].sub_menus.length > 0) {
            for(let j = 0; j < menus.value[i].sub_menus.length; j++ ) {
                path = menus.value[i].sub_menus[j].path;
                if(path && currentPath.value.includes(path)) {
                    key = [menus.value[i].sub_menus[j].key]
                }
            }
        }
    }
    return key;
});

const logout = () => {
    router.replace(`/signin`);
    store.dispatch('setAccessToken');
}
const onProfileClick = () => {
    router.push({ path: `/dashboard/kyc` });
}
const showNotificationIcon = computed(() => {
    if(!userInfo.value.identification_type) return true;
    if(userInfo.value.identification_status == 'PENDING') return true;
    return false;
});
</script>

<style lang="less">
.dashboard-top-header {
    display: flex;
    position: absolute;
    height: auto;
    z-index: 5;
    width: calc(100% - 300px);
    padding: 0 2em;
    right: 0px;
    top: 0px;
    @media screen and (max-width: 910px) {
        width: 100%;
        padding: 0em;
    }
    
    .dashboard-top-header-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        height: 113px;
        background-color: #FFF;
        position: relative;
        width: 100%;
        transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        z-index: 7;
        border-bottom: 1px solid #ccc;
        @media screen and (max-width: 910px) {
            justify-content: space-between;
            padding: 0 20px;
        }
        @media screen and (max-width: 415px) {
            height: 80px;
            justify-content: space-between;
            padding: 0 16px;
        }
        .logo {
            display: none;
            flex-wrap: wrap;
            color: @primary-color;
            font-weight: 800;
            align-items: center;
            justify-content: center;
            align-content: center;
            @media screen and (max-width: 910px) {
                display: flex;
            }
            img {
                width: 50px;
                border-radius: 8px;
                margin: 8px;
                @media screen and (max-width: 415px) {
                    width: 34px;
                    margin: 4px;
                }
            }
            .name {
                font-size: 32px;
                @media screen and (max-width: 415px) {
                    font-size: 28px;
                }
            }
        }
        .user {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            cursor: pointer;
            min-width: 200px;
            .anticon {
                display: none;
            }
            @media screen and (max-width: 910px) {
                min-width: auto;
                height: 42px;
                .anticon {
                    display: block;
                    font-size: 32px;
                }
            }
            .my-user-card {
                display: flex;
                line-height: 1em;
                align-items: center;
                justify-content: center;
                color:#333;
                padding: 0.6em 1em;
                width: 100%;
                position: relative;
                .initial {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: @primary-color;
                    
                    @media screen and (max-width: 910px) {
                        display: none;
                    }
                }
                .name {
                    margin-left: 0.5em;
                    max-width: 160px;
                    line-height: 1.4em;
                    @media screen and (max-width: 910px) {
                        display: none;
                    }
                    .display-name {
                        font-size: 18px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap; 

                    }
                    .email {
                        font-size: 14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .notification {
                    font-size: 0.8em;
                    background-color: rgb(174, 0, 0);
                    color: #FFF;
                    border-radius: 50%;
                    padding: 5px;
                    position: absolute;
                    right: 4px;
                    top: 4px;
                    opacity: 0.7;
                    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                }
                &:hover {
                    .notification {
                        opacity: 1;
                        transform: scale(1.1);
                    }
                }
            }
            
            .profile-menu {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                background-color: #FFF;
                border-radius: 8px;
                box-shadow: 0px 1.66744px 16.6744px rgba(0, 0, 0, 0.1);
                transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                width: 100%;
                
                @media screen and (max-width: 900px) {
                    display: none;
                }
                .profile-menu-user-card-container {
                    padding-bottom: 16px;
                    display: flex;
                    align-items: flex-start;
                    @media screen and (max-width: 415px) {
                        height: 81px;
                    }
                }
                .profile-menu-items {
                    line-height: 1.8em;
                    padding: 1em 0em;
                    background-color: rgba(0,0,0,0.02);
                    .profile-menu-item {
                        padding: 0.5em 1em;
                        cursor: pointer;
                        transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                        display: flex;
                        flex-direction: column;
                        &:hover {
                            color: @primary-color;
                            background-color: rgba(0, 0, 0, 0.03);
                        }
                        .warning {
                            color: #ae0000;
                            font-size: 0.6em;
                            line-height: 1em;
                            font-style: italic;
                        }
                    }
                }
            }
            &.show {
                cursor: default;
                .profile-menu {
                    opacity: 1;
                }
            }
        }
        .nav-show-btn {
            display: none;
            & > * {
                font-size: 30px;
            }
            @media screen and (max-width: 910px) {
                display: block;
            }
        }
    }
    .mobile-nav {
        width: 100%;
        display: none;
        padding: 32px 0px;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0 8px 20px -16px rgba(0,0,0,0.3) inset;
        height: calc(100vh - 114px);
        position: absolute;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        z-index: 100;
        top: 114px;
        left: 0px;
        @media screen and (max-width: 910px) {
            display: block;
        }
        @media screen and (max-width: 415px) {
            height: calc(100vh - 80px);
            top: 80px;
            display: flex;
            flex-direction: column;
            top: 80px;
            padding: 0px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .mobile-nav-menu {
            font-size: 20px;
            border-bottom: none;
            border-right: none;
            background-color: transparent;
            text-align: center;
            @media screen and (max-width: 415px) {
                font-size: 16px;
                line-height: 40px
            }
            .ant-menu-item {
                line-height: 60px;
                padding: 0px;
                padding-right: 0px !important;
                padding-left: 0px !important;
                height: 60px;
                margin: 0;
                user-select: none;
                @media screen and (max-width: 415px) {
                    line-height: 50px;
                    height: 50px;
                }
                .ant-menu-title-content {
                    text-align: center;
                    @media screen and (max-width: 415px) {
                        line-height: 50px
                    }
                }
                &:active {
                    background-color: rgb(67, 67, 67);
                    color: @primary-color
                }
            }
            .ant-menu-submenu {
                display: flex;
                flex-direction: column;
                min-height: 40px;
                height: auto;
                border-right: none;
                .ant-menu-submenu-title {
                    padding: 0px;
                    padding-left: 0px !important;
                    margin: 0px;
                }
                .ant-menu-sub {
                    background-color: transparent;
                    padding: 16px 0px;
                    .ant-menu-item {
                        height: 30px;
                        .ant-menu-title-content {
                            line-height: 30px;
                        }
                        &:hover {
                            color: #00B77E;
                        }
                        &.ant-menu-item-selected {
                            background-color: transparent;
                            color: #00B77E;
                        }
                    }
                }
            }
            .ant-menu-item-selected {
                background-color: #333;
                &::after {
                    display: none;
                }
            }
            .ant-menu-submenu-arrow {
                display: none;
            }
        }
        .user {
            
            padding: 1em 0em;
            align-items: center;
            justify-content: center;
            align-content: center;
            display: none;
            @media screen and (max-width: 910px) {
                display: flex;
                flex-direction: column-reverse;
            }
            .my-user-card {
                display: flex;
                line-height: 1em;
                align-items: center;
                justify-content: flex-start;
                border-radius: 8px;
                background-color: #eee;
                padding: 1em;
                color:#333;
                .initial {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: @primary-color;
                    font-size: 1.3em;
                }
                .name {
                    margin-left: 0.5em;
                    font-size: 20px;
                    .display-name {
                        line-height: 1.5em;
                    }
                    .email {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>