import { createStore, useStore as useVuexStore } from "vuex";
import countryCode from './countryCode.json';
import axios from 'axios';
import { inflate, deflate } from 'pako';
import router from '../router';
import { io } from "socket.io-client";
import { notification } from 'ant-design-vue';
import moment from 'moment';

const ACCESSTOKEN = 'accessToken';
const LST_ACCESS = 'last_access'
const USERINFO = 'userInfo';
const browserToken = 'browserToken';
// const DOMAIN = 'http://localhost:9090';
// const DOMAIN = import.meta.env.DOMAIN;
// const DOMAIN = 'https://uatapi.otcpro.io';
const DOMAIN = 'https://prodapi.otcpro.io';
const BASEURL = `${DOMAIN}/api/`;

const req = axios.create({
    baseURL: BASEURL,
    timeout: 60000,
});

export interface UserInfo {
    activated: number
    authenticate_guid:number
    disabled:number
    display_name:string
    email:string
    guid:string
    authenticate_referral_code:string|null,
    identification_type:string|null
    identification_status:string|null
    username:string|null
    initial:string
}
export interface State {
    accessToken: string | null,
    last_access_str: string | null,
    userInfo: UserInfo | null,
    browserToken: string | null,
    currencies: Array<any> | null,
    socket: any | null,
    maintenance: any | null,
    mobileMenuOpen: boolean,
}


interface OrderResultInfo {
    exchange_guid: string | null,
    instrument: string,
    deal_quantity: number,
    deal_price: number,
    order_type: string,
    side: string,
    status: number,
    user_guid: string,
    accept_slippage_bps: number,
    msg: string,
}
interface OrderResult {
    result: OrderResultInfo,
}

const store = createStore<State>({
    state: {
        accessToken: null,
        last_access_str: null,
        userInfo: null,
        browserToken: null,
        currencies: [],
        maintenance: {
            in_maintenance: false,
            results: []
        },
        socket: null,
        mobileMenuOpen: false,
    },
    getters: {
        accessToken: state => state.accessToken,
        last_access_str: state => state.last_access_str,
        userInfo: state => state.userInfo,
        currencies: state => state.currencies,
        domain: () => { return DOMAIN },
        countryCode: () => countryCode,
        inMaintenance: (state) => {
            return state.maintenance&&state.maintenance.in_maintenance?state.maintenance.in_maintenance:false;
        },
        maintenance: (state) => {
            return state.maintenance&&state.maintenance.results?state.maintenance.results[0]:null;
        },
        mobileMenuOpen: state => state.mobileMenuOpen,
    },
    mutations: {
        setIdentificationType(state, type:string|null = null) {
            if(state.userInfo) {
                state.userInfo.identification_type = type;
                if(type == null) {
                    state.userInfo.identification_status = null;
                }
            }
        },
        setDisplayName(state, display_name:string) {
            if(state.userInfo) {
                state.userInfo.display_name = display_name;
            }
        },
        setIdentificationStatus(state, status:string|null) {
            if(state.userInfo) {
                state.userInfo.identification_status = status;
            }
        },
        setMobileMenuOpenStatus(state, status = false) {
            state.mobileMenuOpen = status;
        },
    },
    actions: {
        init() {
            try {
                axios.defaults.baseURL = BASEURL;
                this.state.last_access_str = localStorage.getItem(LST_ACCESS) || null;
                this.state.accessToken = localStorage.getItem(ACCESSTOKEN) || null;

                let _userInfo = localStorage.getItem(USERINFO) || null;
                if (!this.state.accessToken || this.state.accessToken == 'null' || this.state.accessToken == 'undefined' || this.state.accessToken.length == 0) {
                    this.dispatch("setAccessToken");
                }

                if (!_userInfo || _userInfo == 'null' || _userInfo == 'undefined') {
                    this.dispatch("setAccessToken");
                }
                else {
                    this.state.userInfo = JSON.parse(_userInfo);
                }

                if (this.getters.isSuperAdmin) {
                    this.dispatch("getCompanies");
                }
                this.dispatch('getCurriencies');
            }
            catch (err) {
                this.dispatch("setAccessToken");
            }
        },
        setLastAccess(event) {
            localStorage.setItem(LST_ACCESS, moment().format());
        },
        setAccessToken(event, { token = null } = {}) {
            this.state.accessToken = token;

            if (this.state.accessToken) {
                localStorage.setItem(ACCESSTOKEN, this.state.accessToken);
            }
            else {
                localStorage.removeItem(ACCESSTOKEN);
                this.dispatch("setUserInfo");
                this.dispatch("setbrowserToken");
            }
        },

        setbrowserToken(event, token = null) {
            this.state.browserToken = token;
            if (typeof token == 'string') {
                localStorage.setItem(browserToken, token);
            }
        },

        setUserInfo(event, { user = null, userInfo = null } = {}) {
            this.state.userInfo = user || userInfo;
            if (this.state.userInfo) {
                localStorage.setItem(USERINFO, JSON.stringify(this.state.userInfo));
            }
            else {
                localStorage.removeItem(USERINFO);
            }
        },
        request(event, { method = "GET", endpoint, headers = { 'Content-Type': 'application/json', Authorization: "" }, data, params, responseType }) {
            let ctrl = this;
            if (this.state.accessToken && !headers['Authorization']) {
                headers['Authorization'] = "Bearer " + this.state.accessToken;
            }
            let _data = data;

            let config = { 
                method, url: endpoint, headers, responseType, 
                data: _data, 
                // data,
                params 
            }
            // if (responseType) { config.responseType = responseType; }
            // if (data) { config.data = data; }
            // if (params) { config.params = params; }
            return new Promise((resolve, reject) => {
                req(config).then(d => {
                    if (d.data.__d) {
                        d.data = JSON.parse(inflate(d.data.__d, { to: 'string' }));
                    }
                    if(d.headers[`refresh-token`]) {
                        ctrl.dispatch("setAccessToken", { token: d.headers[`refresh-token`] });
                    }
                    resolve(d);
                }).catch(err => {
                    if (err.message && err.message.indexOf('timeout') >= 0) {
                        reject({
                            title: 'Request timeout',
                            message: 'Request timeout, please wait and try again.'
                        })
                    }
                    else if (err.response && err.response.data && err.response.data.name == "JWT_TOKEN_ERROR") {
                        ctrl.dispatch("setAccessToken");
                        router.replace('/session-expired');
                    }
                    else {
                        reject(err.response);
                    }
                })
            });
        },
        getError(event, { title = "UNKNOWN ERROR", message = "Unknown error", err = {} } = {}) {
            let result = {
                message: title || err.statusText,
                description: err.message || err.statusText || message
            }
            if (err.data.name) {
                result.message = err.data.name;
            }
            if (err.data.message) {
                result.description = err.data.message;
            }
            return result;
        },
        arrayBufferToBase64(event, buffer: ArrayBufferLike) {
            return new Promise((resolve, reject) => {
                try {
                    var arrayBufferView = new Uint8Array(buffer);
                    var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
                    var urlCreator = window.URL || window.webkitURL;
                    resolve(urlCreator.createObjectURL(blob))
                } catch (err) {
                    reject(err);
                }

            })
        },
        getCurriencies(event) {
            let ctrl = this;
            ctrl.dispatch("request", {endpoint:"/currency", params: {
                where: `enabled=1`
            }}).then(({ data }) => {
                ctrl.state.currencies = data.results;
            });
        },
        checkLoginStatus(event) {
            let ctrl = this;
            ctrl.dispatch("request", { endpoint:"authenticate/tkn" }).then(({ data }) => {
                if(data.vld != 1) {
                    ctrl.dispatch(`setAccessToken`);
                }
            });
        },
        checkMaintenance(event) {
            let ctrl = this;
            ctrl.dispatch("request", {endpoint:"/public/maintain"}).then(({ data }) => {
                ctrl.state.maintenance = data;
            });
        },
    }
});

export function useStore(): typeof store {
    return useVuexStore();
}
export default store;
// export type Store = Omit<VuexStore<any>, "commit"> & {
//     commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
//       key: K,
//       payload: P,
//       options?: CommitOptions
//     ): ReturnType<Mutations[K]>;
//    };