export default {
    global: {
        alert: {
            session_expired_title: `您的会话已过期`,
            session_expired_subtitle: `抱歉，您的会话已过期, 请重新登陆。`,
            staging_alert_msg: `警告`,
            staging_alert_desc: `请注意, 您正在使用的是测试环境。请不要进行任何存取以及买卖操作以避免不必要的损失.`,
        },
        menu: {
            home: "主页",
            features: "特点",
            privacy: "隐私政策",
            term_of_use: "使用条款",
            buy_sell: "买/卖",
            contact_us: "联系我们",
            faq: "常见问题", 
            dashboard: `交易面板`,
            my_profile: `我的信息`,
            warning_id_not_verified: `身份尚未验证!`,
            btn_sign_up: `注册`,
            btn_sign_in: `登录`,
            btn_sign_off: `注销`,
        }
    },
    home: {
        banner: {
            introducing: `欢迎使用`,
            title: `电子报价平台`,
            desc: `让您的虚拟货币交易变得更简单, 更智慧`,
            signup: `立即注册`
        },
        price: {
            title: `您可以与我们交易的货币`,
            desc: `我们会加入更多的货币, 同时也请告诉我们您希望交易的货币!`,
            buy_price: `买入价`,
            sell_price: `卖出价`,
            aud: `澳元`,
            usd: `美元`,
            others: `其他`,
        },
        feature: {
            title: `主要特点`,
            desc: `我们为何与众不同`,
            steaming_price: `实时价格`,
            steaming_price_desc: `定量监控实时价格 点击完成交易。`,
            no_fee: `无交易费`,
            no_fee_desc: `交易费已是过去, 不必再计算, 所见即所得。`,
            deep_liquidity: `深度流动性`,
            deep_liquidity_desc: `执行大额交易而不必过多切换市场。`,
            compliant: `符合规范`,
            compliant_desc: `我们是澳大利亚交易报告和分析中心注册平台。以全面合规程序确保您的资金始终安全可靠。`,
            future_Release: `即将推出`,
            lock_price: `先锁定价格 24小时内完成交易。`,
            set_limit: `设定限价订单 已待更好的价格。`,
        },
        who_we_are: {
            title: `关于我们`,
            sub_title: `专家团队`,
            dsec: `我们是一个拥有超过5年加密程序交易经验的团队，成功地为主流交易所提供了流动性。现在我们将流动价格带到场外交易市场，帮助您一键管理您的交易。`
        },
        faqs: {
            title: `常见问题`,
            what_is_otcpro: {
                title: `什么是OTCPro`,
                desc: `OTCPro是一个流动性定价的加密货币场外交易平台，允许买家和卖家在指定数量获得实时报价，并一键执行。`
            },
            deposits: {
                title: `存款`,
                crypto_deposits: {
                    title: `加密货币存款`,
                    desc: `OTCPro的每位客户都会获得一个独特的钱包地址来接收加密货币。您可以在仪表板屏幕上点击‘存款’，然后选择您希望存入的货币来找到您的钱包地址。`
                },
                deposit_not_arrived: {
                    title: `存款未到账`,
                    desc: `首先，请确保您发送到正确的钱包地址，然后使用您的交易哈希在区块链上检查交易状态，不同的资产需要不同的确认次数才能完全确认并记入您的帐户。如果您对存款仍有疑问，请联系客户支持。`
                },
                fiat_deposits: {
                    title: `法币存款`,
                    desc: `OTCPro鼓励澳大利亚本地客户使用PayID方法存入澳元。通过PayID存款是免费且快速的。如果您不在澳大利亚或无法使用PayID，我们还提供BSB和帐号来接收法币存款。无论您选择哪种方法，请确保您的银行帐户与OTCPro注册的名称匹配。任何第三方存款都将被保留并标记您的帐户以供审核。`
                },
                how_long_does_it_take: {
                    title: `需要多长时间`,
                    desc: `PayID存款通常是即时的，但首次存款可能需要长达24小时。银行转账通常需要1-3个工作日。`
                },
            },
            withdrawals: {
                title: `提款`,
                how_long_does_it_take: {
                    title: `需要多长时间`,
                    desc: `您的法币和加密货币提款通常会在48小时内处理，但由于服务提供商和银行中断可能会有延迟。`
                },
                withdrawal_limit: {
                    title: `提款限额`,
                    desc: `您可以在仪表板上查看您的每日提款限额。每个用户起初都会获得100,000美元的每日提款限额，这个限额是法币和加密货币共享的。您可以提交请求给我们的支持团队来增加限额，您的请求将根据您的交易量、风险等级等进行审查。`
                },
            },
            trading: {
                title: `交易`,
                desc: `我们的流动性定价交易系统允许用户监控用户定义的数量的实时价格，并一键执行。只需输入您想要的加密货币数量，报价将相应地调整，选择您的交易方向（买/卖），然后交易。`,
                secure_price_inadvance: {
                    title: `预先确定价格`,
                    desc: `此功能允许用户在没有交易的全部价值的情况下进行交易。为了预先确定交易的价格，您需要：`,
                    sub_1: `在您的帐户中持有一定的余额，无论是法币还是数字货币，至少是我们根据您的风险级别设定的预定金额`,
                    sub_2: `在放置交易后的24小时内结算您的交易。延迟结算将产生0.1%的每日费用，超过未付金额。`,
                    paragraph: `您可以向我们的客户支持发送请求以启用此功能。我们需要评估您的财务状况、交易记录和风险级别来决定对您的帐户的风险敞口限制（您用于交易的最大透支金额）。同时，您需要维持最低保证金要求（当进行“预先确定价格”的交易时，您需要在帐户中保留的存款）。`
                },
            },
            referral: {
                title: `推荐`,
                desc: `邀请您的朋友并获得他们交易量的0.05%佣金。推荐佣金在每月底计算并释放到您的帐户。它将直接记入您的澳元余额。`
            },
            fees: {
                title: `费用`,
                list_1: `交易：没有交易费或交换费`,
                list_2: `存款：平台的存款没有收费，无论是法币还是加密货币。我们会将我们这边收到的确切金额反映到您的平台账户`,
                list_3: `提款：澳大利亚本地的澳元提款没有费用，但在异常情况下可能会产生一些费用，包括：
                被拒绝的法币提款：每笔本地交易5.00美元，资金退回后将从您的OTCPro帐户余额中扣除
                国际法币提款：30.00 - 50.00美元的转账费，取决于货币和支付服务提供商的收费。这将从您的提款金额中扣除。`,
                list_4: `迟延结算费：在“预先确定价格”模式下，如果交易在24小时内未结算，将对未付款项收取0.1%的每日利率。`,
                list_5: `由用户引起的任何额外费用都将由客户承担。`,
            },
            account_security: {
                title: `账户安全`,
                desc: `OTCPro使用Google Authenticator应用程序提供双因素身份验证。双因素身份验证是从您的Google Authenticator移动应用程序生成的一次性代码，为您的账户提供额外的安全层。我们强烈建议我们的客户启用2FA来保护您的账户的安全。`
            },
            prohibited_jurisdictions: {
                title: `禁止的司法管辖区`,
                desc: `我们不会为以下司法管辖区的客户提供我们的服务：`,
                list_1: `美国；`,
                list_2: `高风险司法管辖区，包括以下国家：
                阿富汗、博茨瓦纳、布隆迪、喀麦隆、乍得、刚果民主共和国、科特迪瓦、古巴、厄立特里亚、冈比亚、加纳、几内亚、海地、伊朗、伊拉克、朝鲜、吉尔吉斯斯坦、黎巴嫩、莱索托、利比里亚、利比亚、马里、缅甸、尼日尔、索马里、苏丹、叙利亚、乌干达、也门、津巴布韦`,
                list_3: `中风险司法管辖区，包括以下国家：
                安哥拉、孟加拉国、巴林、白俄罗斯、玻利维亚、布基纳法索、柬埔寨、中国、中非共和国、哥伦比亚、科摩罗、厄瓜多尔、埃及、赤道几内亚、埃塞俄比亚、格鲁吉亚、格陵兰、危地马拉、几内亚比绍、梵蒂冈、印度、印度尼西亚、以色列、乔丹、哈萨克斯坦、肯尼亚、科威特、老挝、马达加斯加、马拉维、毛里塔尼亚、尼日利亚、阿曼、巴基斯坦、巴勒斯坦、巴布亚新几内亚、秘鲁、菲律宾、俄罗斯、卡塔尔、卢旺达、圣多美和普林西比、沙特阿拉伯、塞拉利昂、所罗门群岛、斯里兰卡、南非、塔吉克斯坦、东帝汶、多哥、土耳其、土库曼斯坦、阿拉伯联合酋长国、乌兹别克斯坦、委内瑞拉、越南、赞比亚`            
            },
        },
        get_in_touch: {
            title: `联系我们`,
            sub_title_1: `常规查询`,
            sub_title_2: `提交请求`,
            name: `姓名`,
            email: `电子邮件`,
            message: `留言`,
            btn: `发送`
        },
        ribbon: {
            title: `今天就开始交易吧！`,
            btn: `点击注册`
        },
    },
    privacy: {
        h1: `隐私政策`,
        p1: `Rhino Trading Pty Ltd ACN 655 361 345 以OTCPro的商业名称（我们或我们）已在AUSTRAC注册，以提供DCE（数字货币交易所）服务。按法律要求，我们采取措施预防欺诈、洗钱和资助恐怖主义。我们承诺根据1988年《隐私法》Cth保护您的隐私。该法律规定了我们可能收集、使用和披露任何个人的个人信息的方式。`,
        h2: `我们收集哪些信息？`,
        p2: `我们从客户那里收集个人信息，用于身份验证。这可能包括一系列的信息，例如`,
        list1: {
            l1: `关于您的信息：您的姓名、地址、出生日期、联系方式、工作详情、照片识别、账单、`,
            l2: `关于您的财务和交易的信息：银行详情、帐户号码、数字货币帐户、交易行为特征和偏好、资金来源、交易目的`,
            l3: `关于您的浏览器和设备的信息，包括IP地址、浏览器供应商、设备类型和大小、cookie标识符`,
        },
        p3: `如果您是一个法人实体而不是个人，我们可能会收集以下信息，包括但不限于：`,
        list2: {
            l1: `注册和组建文件、`,
            l2: `公司声明证明注册办公室、所有权结构和董事、`,
            l3: `授权信授权用户负责账户、`,
            l4: `如果在监管下提供服务的资质、`,
            l5: `其他符合所有相关法律和法规的信息。`,
        },
        h3: `OTCPro是否共享您的个人信息？`,
        p4: `我们不出售、交易您的信息给他人。我们可能会向第三方供应商和服务提供商、监管机构、政府机关、执法机构和法院披露您的信息。我们可能会在法律要求或在《隐私法》允许的某些不寻常的其他情况下向他人提供您的信息。但是，我们会采取所有合理的步骤确保他们以我们相同的方式保护您的信息。`,
        h4: `我们如何保护您的信息？`,
        p5: `我们认识到确保客户个人信息的重要性。所有这些数据都将在一个隔离的私有网络中分配，我们通过使用加密协议和软件在传输过程中保护您的个人信息的安全。`,
        h5: `访问您的信息`,
        p6: `您可以根据要求获得您的个人信息的副本。您还可以通过登录到您的OTCPro帐户在我们的平台上管理、编辑或更新某些个人信息。我们还会纠正、修改或删除任何我们同意是不准确的、不相关的、过时的或不完整的个人信息。`,
        h6: `政策的修订`,
        p7: `我们可能会不时修改此隐私政策。请定期检查我们的网站以获取隐私政策的最新版本。`,
        p8: `对我们的政策有什么想法吗？`,
        p9: `如果您对我们的隐私政策有任何疑问、担忧或投诉，请从支持页面提交请求或发送邮件至 support{'@'}otcpro.io`
    },
    sign_in: {
        title: `登录`,
        btn_forgot: `忘记密码？`,
        btn_sign_up: `注册新账户`,
        btn_login: `登录`,
        email: '电子邮件地址',
        password: `密码`,
        msg_2fa: `您将会收到一份带有验证码的电子邮件，请前往查收。`,
    },
    sign_up: {
        title: `注册`,
        btn_login: `已经有账户了吗？点击登录`,
        email: '电子邮件地址',
        password: `密码`,
        password_repeat: `请重复密码`,
        referral_code: `推荐码 (可选)`,
        btn_submit: `提交注册`,
    },
    sign_up_success: {
        title: "欢迎使用 OTCPRO",
        sub_title: "感谢您加入 OTCPRO.",
        p1: "我们已向您注册的电子邮件地址发送了验证邮件",
        p2: "在您使用您注册的帐号登录之前，请务必根据邮件内的指示和链接激活您的帐号。",
        btn: "登录"
    }
}