import { useGtag } from "vue-gtag-next";

const { screenview, event, } = useGtag()

interface err {
    message: string,
    description: string
}

export function getError({ title = "UNKNOWN ERROR", message = "Unknown error", data = {name:'Error', message:'Unknown error'}, placement = 'topRight'  } = {}):err{
    let result = {
        message: title,
        description: message,
        placement,
        class: 'otcpro-error-notification',
      }
      if (data) {
          if(data.name) {
            result.message = data.name;
          }
          if(data.message) {
            result.description = data.message;
          }
      }
      return result;
}


export function getAmount(amount = 0, type = 'TOKEN'):string {
    return Intl.NumberFormat('en-US', { minimumFractionDigits: 4 }).format(Number(amount));
}

export function parseAddress({ address = '', network = 'ETH' }):string {
  return address;
}

export const gtagScreenview = (screen_name: string) => {
  screenview({
      screen_name,
      app_name: 'OTCPRO'
  })
}
export const gtagEvent = ({ name, event_category, event_label }: {
  name: string;
  event_category: string;
  event_label: string;
}) => {
  event(name, {
      event_category,
      event_label,
  });
}