<template>
<a-layout-footer class="otcpro-footer">
    <div class="otcpro-footer-container" :class="{private:privateSection}">
        <div class="site-info" >
            <div class="logo">OTCPro</div>
            <div class="copyright">Copyright 2024 OTCPro <br/>All rights reserved</div>
        </div>
        <div class="site-map">
            <a-button type="text" v-for="link in links" :key="link" :href="link.path">{{link.title}}</a-button>
        </div>
    </div>
</a-layout-footer>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { h, ref, reactive, computed, watch, onMounted } from "vue";
import { useI18n } from 'vue-i18n'
const { t:i18n } = useI18n();
const route = useRoute();


interface SiteMapLink {
    title: string,
    path: string,
}

const privateSection = computed(() => {
    return route.path.startsWith(`/dashboard`)
    // || route.path.startsWith(`/session-expired`)
    // || route.path.startsWith(`/404`);
});

const links = computed(() => {
    if(privateSection.value) {
        return [
            {title: i18n(`global.menu.home`), path: `/`},
            {title: i18n(`global.menu.privacy`), path: `/privacy`},
            {title: i18n(`global.menu.term_of_use`), path: `/term_of_use`},
            {title: i18n(`global.menu.faq`), path: `/faqs`},
        ]
    }
    return [
        {title: i18n(`global.menu.home`), path: `/`},
        {title: i18n(`global.menu.features`), path: `/features`},
        {title: i18n(`global.menu.faq`), path: `/faqs`},
        {title: i18n(`global.menu.contact_us`), path: `/contact_us`},
        {title: i18n(`global.menu.privacy`), path: `/privacy`},
        {title: i18n(`global.menu.term_of_use`), path: `/term_of_use`},
    ]
})
</script>

<style lang="less">
.otcpro-footer.ant-layout-footer {
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: 100%;
    min-height:160px;
    padding: 0 2em;
    .otcpro-footer-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        width:100%;
        max-width: 1280px;
        font-weight: 700;
        padding: 1em;
        border-top: 1px solid #ccc;
        &.private {
            justify-content: flex-start;
            .site-map {
                padding: 1em;
                justify-content: flex-end;
                @media screen and (max-width: 910px) {
                    justify-content: center;
                }
            }
        }
        @media screen and (max-width: 910px) {
            padding: 0px 32px 64px;
            flex-direction: column;
        }
        .site-info {
            font-weight: 600;
            @media screen and (max-width: 910px) {
                text-align: center;
                padding: 32px;
            }
            .logo {
                color: @primary-color;
                font-weight: 900;
                font-size: 34px;
            }
        }
        .copyright {
            font-size: 12px;
            line-height: 1.4em;
        }
        .site-map {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            flex:1;
            @media screen and (max-width: 910px) {
                justify-content: center;
            }
            .ant-btn {
                font-size: 16px;
                font-weight: 500;
                line-height: 30px;
                color:#223843;
                height:40px;
                padding: 5px 20px;
                padding-top:5px !important;
            }
        }
    }
}
</style>