export default {
    global: {
        alert: {
            session_expired_title: `Session Expired`,
            session_expired_subtitle: `Sorry, your session has expired. Please login again.`,
            staging_alert_msg: `Warning`,
            staging_alert_desc: `Please be aware this is an test environment. Please DO NOT make any deposit or trading to avoid any loss.`,
        },
        menu: {
            home: "Home",
            features: "Features",
            privacy: "Privacy",
            term_of_use: "Term of Use",
            buy_sell: "Buy/Sell",
            contact_us: "Contact Us",
            faq: "FAQs", 
            dashboard: `Dashboard`,
            my_profile: `My Profile`,
            warning_id_not_verified: `Identify not verified!`,
            btn_sign_up: `Sign up`,
            btn_sign_in: `Sign in`,
            btn_sign_off: `Sign off`,
        }
    },
    home: {
        banner: {
            introducing: `Introducing`,
            title: `Electronic Pricing`,
            desc: `Your crypto trades just got simpler and smarter`,
            signup: `Sign Up`
        },
        price: {
            title: `Assets you can trade us with`,
            desc: `With more to come, tell us what coins you want to trade!`,
            buy_price: `Buy Price`,
            sell_price: `Sell Price`,
            aud: `AUD`,
            usd: `USD`,
            others: `Others`,
        },
        feature: {
            title: `TOP FEATURES`,
            desc: `That sets us apart from the ordinary`,
            steaming_price: `Streaming Price`,
            steaming_price_desc: `Monitor live prices in defined quantities and settle in one click.`,
            no_fee: `No Fees`,
            no_fee_desc: `Fees are in the past. Save your calculation, what you see is what you get.`,
            deep_liquidity: `Deep Liquidity`,
            deep_liquidity_desc: `Execute your large orders without moving the market too much.`,
            compliant: `Compliant`,
            compliant_desc: `AUSTRAC registered platform. Our comprehensive compliance programs ensure your funds are always safe and secure.`,
            future_Release: `Future Release`,
            lock_price: `Lock price before sending fund and settle in 24h.`,
            set_limit: `Set limit orders when you expect a better execution price.`,
        },
        who_we_are: {
            title: `Who We Are`,
            sub_title: `A Team of Experts`,
            dsec: `We are a team with 5+ years crypto program trading experience, who has successfully supported liquidity of leading exchanges. Now we bring streaming price to OTC market, help you to manage your trade in one click.`
        },
        faqs: {
            title: `FAQs`,
            what_is_otcpro: {
                title: `What is OTCPro`,
                desc: `OTCPro is a streaming priced cryptocurrency OTC trading platform, allow buyers and sellers to get live quote at defined quantities, and execute at one click.`
            },
            deposits: {
                title: `Deposits`,
                crypto_deposits: {
                    title: `Crypto deposits`,
                    desc: `Every customer of OTCPro will be given a unique wallet address to receive cryptos. You may find your wallet address in the Dashboard screen by click ‘deposit’ and then select the currency you wish to deposit. `
                },
                deposit_not_arrived: {
                    title: `Deposit not arrived`,
                    desc: `Firstly, make sure you send to the correct wallet address, then check the transaction status on blockchain with your transaction hash, different assets take different confirmations to be fully confirmed and credited to your account. If you still have questions about your deposits, please reach out to customer support.`
                },
                fiat_deposits: {
                    title: `Fiat deposits`,
                    desc: `OTCPro encourages Australia local customers to use PayID method to deposit AUD. Making deposit via PayID is free and fast. If you are out of Australia or unable to use PayID, we also provide BSB & Account number to receive fiat deposit. No matter what method you choose, please make sure your bank account matches the name registered with OTCPro. Any third-party deposits will be hold and flag your account to review.`
                },
                how_long_does_it_take: {
                    title: `How long does it take`,
                    desc: `PayID deposit is usually instant, but the fist deposit may take up to 24hrs. Bank transfer usually takes 1-3 business day.`
                },
            },
            withdrawals: {
                title: `Withdrawals`,
                how_long_does_it_take: {
                    title: `How long does it take`,
                    desc: `Withdrawals of your fiat and cryptos usually will be processed within 48 hours, but delays happen due to service provider and banking interruption. `
                },
                withdrawal_limit: {
                    title: `Withdrawal limit`,
                    desc: `You can view your daily withdrawal limit on the dashboard. Every user will be given $100,000 USD daily withdrawal limit to start from, this limit is shared by fiat and crypto currency. Your can increase the limit by submitting a request to our support team, your request will be reviewed based on your trading volume, risk level, etc. `
                },
            },
            trading: {
                title: `Trading`,
                desc: `Our streaming-priced trading system allows user to monitor live price in user-defined quantities and execute in one click. Simply type in your desired quantity of cryptos, the quotes will adjusted accordingly, select the direction of your trade (Buy/ Sell), then deal. `,
                secure_price_inadvance: {
                    title: `Secure price in advance`,
                    desc: `This function allows user to enter a trade without having the full value of the trade. To secure price in advance for a trade, you need to:`,
                    sub_1: `Hold a balance in your account, either in fiat or digital currencies, of at least the pre-determined amount we set based on your risk level`,
                    sub_2: `Settle your trades within 24 hours of you placing the trade. Late settlement will incur fees at a daily rate of 0.1% over the outstanding amount.`,
                    paragraph: `You can send a request to our customer support to enable this feature. We need to evaluate your financials, trading records and risk level to decide the risk exposure limit to your account(the maximum overdraft amount you can use for trading). In the meantime, you need to maintain a minimum margin requirement (the deposit you need to keep in your account when perform “secure price in advance” trades).`
                },
            },
            referral: {
                title: `Referral`,
                desc: `Invite your friend and get 0.05% commission of their trading volumes. Referral commission is calculated and released to your account at the end of each month. It will directly credit to your AUD balance.`
            },
            fees: {
                title: `Fees`,
                list_1: `Trading: there is no trading fees or exchange fees`,
                list_2: `Deposits: There is no charge on deposits to the platform, both for fiat and cryptos. We will reflect the exact amount received on our side to your platform account`,
                list_3: `Withdrawals: No fees for Australia local AUD withdrawal, however, some fees might be incurred on abnormal occasions, including:
                Rejected fiat withdrawals: $5.00 each local transaction, which will be deducted from your OTCPro account balance after the funds bounce back
                International fiat withdrawals: $30.00 - $50.00 transfer fee, depending on the currency and payment service provider charge. It will be deducted from your withdrawal amount. `,
                list_4: `Late settlement fee: when under “secure price in advance” mode, interest will be charged on the outstanding sum at a daily rate of 0.1% if the transaction is not settled within 24 hours.`,
                list_5: `Any extra fees caused by users will be at the customer’s expense.`,
            },
            account_security: {
                title: `Account Security`,
                desc: `OTCPro uses the Google Authenticator application to provide two-factor authentication. Two-factor authentication is a one-time code generated from your Google Authenticator mobile application, to give your account an additional layer of security. We strongly encourage our customers to enable 2FA to protect the safety of your account.`
            },
            prohibited_jurisdictions: {
                title: `Prohibited Jurisdictions`,
                desc: `We will not provide our services to Clients based in the following jurisdictions:`,
                list_1: `United State of America;`,
                list_2: `High risk jurisdictions which include the following:
                Afghanistan, Botswana, Burundi, Cameroon, Chad, The Democratic Republic of the Congo, Cote D'Ivoire, Cuba, Eritrea, Gambia, Ghana, Guinea, Haiti, Iran, Iraq, North Korea, Kyrgyzstan, Lebanon, Lesotho, Liberia, Libyan Arab Jamahirya, Mali, Myanmar, Niger, Somalia, Sudan, Syria, Uganda, Yemen, Zimbabwe`,
                list_3: `Medium risk jurisdiction which include the following:
                Angola, Bangladesh, Bahrain, Belarus, Bolivia, Burkina Faso, Cambodia, China, Central African Republic, Colombia, Comoros, Ecuador, Egypt, Equatorial Guinea, Ethiopia, Georgia, Greenland, Guatemala, Guinea-Bissau, Holy See (Vatican City State), India, Indonesia, Israel, Jordan, Kazakhstan, Kenya, Kuwait, Lao People's Democratic Republic, Madagascar, Malawi, Mauritania, Nigeria, Oman, Pakistan, Palestine, Papua New Guinea, Peru, Philippines, Russian Federation, Qatar, Rwanda, Sao Tome and Principe, Saudi Arabia, Sierra Leone, Solomon Islands, Sri Lanka, South Africa, Tajikistan, Timor-Leste, Togo, Turkey, Turkmenistan, United Arab Emirates, Uzbekistan, Venezuela, Vietnam, Zambia`
            },
        },
        get_in_touch: {
            title: `Get In Touch`,
            sub_title_1: `General Enquiries`,
            sub_title_2: `Submit a Request`,
            name: `Name`,
            email: `Email`,
            message: `Message`,
            btn: `Send`
        },
        ribbon: {
            title: `Get Your Trading Started Today!`,
            btn: `Sign Up`
        },
    },
    privacy: {
        h1: `Privacy Policy`,
        p1: `Rhino Trading Pty Ltd ACN 655 361 345 with Business name of OTCPro(we or us) is registered with AUSTRAC to provide DCE(DIGITAL CURRENCY EXCHANGE) service, as required by law to take steps to prevent fraud, money-laundering and terrorist-financing. We are committed to protecting your privacy in accordance with the Privacy Act 1988 Cth. The law governs the way in which we may collect, use and disclose personal information about any individual.`,
        h2: `What information do we collect?`,
        p2: `We collect personal information from customers for identity verification purposes. This can include a broad range of information ranging from`,
        list1: {
            l1: `Information about you: your name, address, date of birth, contact details, employment details, Photographic identification, bills,`,
            l2: `Information about your financials and transactions: banking details, account numbers, digital currency account, trading behavioural characteristics and preferences, source of funds, trading purposes`,
            l3: `Information about your browser and device, including IP addresses, browser vendor, device type and size, cookie identifiers`,
        },
        p3: `Where you are a legal entity other than an individual, we may collect below information, including, but not limited to:`,
        list2: {
            l1: `registration and incorporation documents,`,
            l2: `company statement evidencing registered office, ownership structure and directors,`,
            l3: `authorization letters to authorize users responsible for the account,`,
            l4: `qualifications if providing services under regulation,`,
            l5: `other information comply with all relevant laws and regulations.`,
        },
        h3: `Does OTCPro share your personal information?`,
        p4: `We do not sell, trade your information to others. We may disclose your information to third party suppliers and service providers, regulatory bodies, government agencies, law enforcement bodies and courts. We may provide your information to others if we are required to do so by law or under some unusual other circumstances which the Privacy Act permits. However, we will take all reasonable steps to ensure that they protect your information in the same way that we do.`,
        h4: `How do we protect your information?`,
        p5: `We recognise the importance of securing the personal information of our customers. All these data will be allocated in an isolated private network, and we work to protect the security of your personal information during transmission by using encryption protocols and software.`,
        h5: `Accessing your information`,
        p6: `You can obtain a copy of your Personal Information upon request. You can also manage, edit, or update certain personal information on our platform by logging into your OTCPro account. We will also correct, amend or delete any personal information that we agree is inaccurate, irrelevant, out of date or incomplete.`,
        h6: `Amendments to the policy`,
        p7: `We may from time to time vary this Privacy Policy. Please check our website regularly for an up-to-date version of our Privacy Policy.`,
        p8: `Have a thought about our policy?`,
        p9: `If you have any questions, concerns or complaints about our privacy policy, please submit a request from support page or email us at support{'@'}otcpro.io`
    },
    sign_in: {
        title: `Sign In`,
        btn_forgot: `Forgot Password?`,
        btn_sign_up: `Sign Up`,
        btn_login: `Login`,
        email: 'Email',
        password: `Password`,
        msg_2fa: `We've sent a code to your email.`,
    },
    sign_up: {
        title: `Sign Up`,
        btn_login: `Already have a account? Login`,
        email: 'Email',
        password: `Password`,
        password_repeat: `Repeat Password`,
        referral_code: `Referral Code (Optional)`,
        btn_submit: `Submit`,
    },
    sign_up_success: {
        title: "Welcome to OTCPRO",
        sub_title: "Thanks for signing up to OTCPRO.",
        p1: "Before you can login with your account, we have send you a verification email.",
        p2: "Please follow the instrucation and link to activate your account.",
        btn: "Sign in"
    }
}