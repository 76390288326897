import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      title: 'Home',
      metaTags: [
        {
          name: `description`,
          content: `Landing page of OTCPro.`
        }
      ]
    }
  },
  {
    path: "/signin",
    name: "SignIn",
    props: true,
    component: () => import("../views/SignIn.vue"),
    meta: {
      title: 'Sign In',
      metaTags: [
        {
          name: `description`,
          content: `Login to OTCPro.`
        }
      ]
    }
  },
  {
    path: "/signup",
    name: "SignUp",
    props: true,
    component: () => import("../views/SignUp.vue"),
    meta: {
      title: 'Sign Up',
      metaTags: [
        {
          name: `description`,
          content: `Regiser to OTCPro.`
        }
      ]
    },
    children: [
      {
        path: ":referral",
        name: "MyDashboard",
        props: true,
        component: () => import("../views/SignUp.vue"),
      },
    ]
  },
  {
    path: "/signup/:referral",
    name: "SignUpWithReferral",
    props: true,
    component: () => import("../views/SignUp.vue"),
    meta: {
      title: 'Sign Up',
      metaTags: [
        {
          name: `description`,
          content: `Regiser to OTCPro.`
        }
      ]
    },
  },
  {
    path: "/signupsuccess",
    name: "SignUpSuccess",
    props: true,
    component: () => import("../views/SignUpSuccess.vue"),
    meta: {
      title: 'Sign Up Success'
    }
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    props: true,
    component: () => import("../views/ForgotPassword.vue"),
    meta: {
      title: 'Forgot My Password',
      metaTags: [
        {
          name: `description`,
          content: `Find Password.`
        }
      ]
    }
  },
  {
    path: '/activate-account/:token',
    name: 'Activate',
    props: true,
    component: () => import('../views/Activate.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    props: true,
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      title: 'Reset Password',
      metaTags: [
        {
          name: `description`,
          content: `Reset user password`
        }
      ]
    },
    children: [
      {
        path: ":token",
        name: "ResetPasswordFromToken",
        props: true,
        component: () => import("../views/ResetPassword.vue"),
      },
    ]
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    props: true,
    component: () => import("../views/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "MyDashboard",
        props: true,
        component: () => import("../components/Dashboard/MyDashboard.vue"),
      },
      {
        path: "kyc",
        name: "Kyc",
        props: true,
        component: () => import("../views/Kyc.vue"),
        children: [
          {
            path: "",
            name: "UserProfile",
            props: true,
            component: () => import("../components/Kyc/UserProfile.vue"),
          }
        ]
      },
      {
        path: "wallet/:asset_name",
        name: "MyWallet",
        props: true,
        component: () => import("../components/Wallet/Wallet.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        props: true,
        component: () => import("../components/Kyc/UserProfile.vue"),
      },
      {
        path: "trade",
        name: "Trade",
        props: true,
        component: () => import("../views/Trade.vue"),
        children: [
          {
            path: "",
            name: "MyTrade",
            props: true,
            component: () => import("../components/Trade/MyTrade.vue"),
          },
          {
            path: ":exchange_request_guid",
            name: "TradeDetails",
            props: true,
            component: () => import("../components/Trade/TradeDetail.vue"),
          },
        ]
      },
      {
        path: "withdraw",
        name: "Withdraw",
        props: true,
        component: () => import("../components/Withdraw/Withdraw.vue"),
        children: [
          {
            path: "",
            name: "MyWithdraw",
            props: true,
            component: () => import("../components/Withdraw/MyWithdraw.vue"),
          },
          {
            path: "token",
            name: "AddressBook",
            props: true,
            component: () => import("../components/Withdraw/AddressBookTokens.vue"),
          },
          {
            path: "bank",
            name: "BankAccounts",
            props: true,
            component: () => import("../components/Withdraw/AddressBookBanks.vue"),
          },
        ]
      },
      {
        path: "deposit",
        name: "Deposit",
        props: true,
        component: () => import("../components/Dashboard/Deposit.vue"),
      },
      {
        path: "history",
        name: "History",
        props: true,
        component: () => import("../views/History.vue"),
        children: [
          {
            path: ":view",
            name: "HistoryView",
            props: true,
            component: () => import("../views/History.vue"),
          },
          // {
          //   path: "",
          //   name: "TradeHistory",
          //   props: true,
          //   component: () => import("../components/Trade/TradeHistory.vue"),
          // },
          // {
          //   path: "deposit",
          //   name: "DepositHistory",
          //   props: true,
          //   component: () => import("../components/Deposit/DepositHistory.vue"),
          // },
          // {
          //   path: "withdraw",
          //   name: "WithdrawHistory",
          //   props: true,
          //   component: () => import("../components/Withdraw/WithdrawHistory.vue"),
          // },
          // {
          //   path: "commission",
          //   name: "commissionHistory",
          //   props: true,
          //   component: () => import("../components/Referrals/ReferralHistory.vue"),
          // },
        ]
      },
      {
        path: "referral",
        name: "Referrals",
        props: true,
        component: () => import("../components/Referrals/MyReferrals.vue"),
      },
      {
        path: "security",
        name: "Security",
        props: true,
        component: () => import("../views/Security.vue"),
        children: [
          {
            path: "",
            name: "ChangePassword",
            props: true,
            component: () => import("../components/Global/UpdatePwdForm.vue"),
          },
          {
            path: "2fa",
            name: "Change2Fa",
            props: true,
            component: () => import("../components/Global/Update2FaForm.vue"),
          },
          {
            path: "developer",
            name: "DeveloperApiKey",
            props: true,
            component: () => import("../components/Global/DeveloperApi.vue"),
          }
        ]
      },
      {
        path: 'support',
        name: 'Support',
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/Support.vue'),
        meta: {
          title: 'Support',
          metaTags: [
            {
              name: `support`,
              content: `Support.`
            }
          ]
        },
        children: [
          {
            path: "",
            name: "contactUsForm",
            props: true,
            component: () => import("../components/Support/ContactUsForm.vue"),
          },
        ]
      },
    ]
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue'),
    meta: {
      title: 'Privacy',
      metaTags: [
        {
          name: `description`,
          content: `Privacy Policy.`
        }
      ]
    }
  },
  {
    path: '/term_of_use',
    name: 'TermOfUse',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermOfUse.vue'),
    meta: {
      title: 'Term of Use',
      metaTags: [
        {
          name: `description`,
          content: `Term of Use.`
        }
      ]
    }
  },
  {
    path: '/about_us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue'),
    meta: {
      title: 'About Us',
      metaTags: [
        {
          name: `description`,
          content: `Who we are.`
        }
      ]
    }
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: () => import(/* webpackChunkName: "about" */ '../views/FAQs.vue'),
    meta: {
      title: 'FAQs',
      metaTags: [
        {
          name: `description`,
          content: `Frequently Asked Question.`
        }
      ]
    }
  },
  {
    path: '/contact_us',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue'),
    meta: {
      title: 'Contact Us',
      metaTags: [
        {
          name: `description`,
          content: `Contact Us.`
        }
      ]
    }
  },
  {
    path: '/features',
    name: 'Features',
    component: () => import(/* webpackChunkName: "about" */ '../views/Features.vue'),
    meta: {
      title: 'Features',
      metaTags: [
        {
          name: `description`,
          content: `Features.`
        }
      ]
    }
  },
  {
    path: '/session-expired',
    name: 'TokenExpired',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TokenExpired.vue')
  },
  {
    path: '/403',
    name: '403',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/403.vue')
  },
  {
    path: '/500',
    name: '500',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/500.vue')
  },
  {
    path: '/404',
    name: 'PageNotExist',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
trackRouter(router);

export default router;