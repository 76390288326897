<template>
<a-layout-header class="global-top-header" :class="{home:!isHome&&!isLogin}" v-if="isHome || isLogin">
    <div class="global-top-header-container">
        <div class="logo" @click="onItemClick()">
            <img src="../../assets/logo.svg" alt="">
            <div class="name">OTCPro</div>
        </div>
        <div class="nav">
            <a-menu class="nav-menu" v-model:selectedKeys="selectedKeys" mode="horizontal" :disabledOverflow="true">
                <a-menu-item key="TRADE" :title="i18n('global.menu.buy_sell')" @click="onTradeClick">{{ i18n('global.menu.buy_sell') }}</a-menu-item>
                <a-menu-item v-for="menu in menus" :key="menu.path" :title="menu.name" @click="onItemClick(menu.path)">{{menu.name}}</a-menu-item>
            </a-menu>
            <div class="user" :class="{show: showProfileMenu&&!showMobileMenu}" v-if="userInfo" @click="onDashBoardClick()">
                <div class="my-user-card">
                    <div class="initial">{{userInfo.initial}}</div>
                    <div class="name">
                        <div class="display-name">{{userInfo.display_name}}</div>
                        <div class="email" v-if="userInfo.display_name!=userInfo.email">{{userInfo.email}}</div>
                    </div>
                    <BellFilled class="notification" v-if="showNotificationIcon" />
                </div>
                <transition name="fade">
                    <div class="profile-menu" v-if="showProfileMenu&&!showMobileMenu">
                        <div class="profile-menu-user-card-container">
                            <div class="my-user-card" >
                                <div class="initial">{{userInfo.initial}}</div>
                                <div class="name">
                                    <div class="display-name">{{userInfo.display_name}}</div>
                                    <div class="email" v-if="userInfo.display_name!=userInfo.email">{{userInfo.email}}</div>
                                </div>
                                <BellFilled class="notification" v-if="showNotificationIcon" />
                            </div>
                        </div>
                        <div class="profile-menu-items">
                            <div class="profile-menu-item" v-if="isHome" @click="() => { router.push({ path: `/dashboard` }) }">
                                <div>{{ i18n('global.menu.dashboard') }}</div>
                            </div>
                            <div class="profile-menu-item" @click="onProfileClick">
                                <div>{{ i18n('global.menu.my_profile') }}</div>
                                <div class="warning" v-if="!userInfo.identification_type || userInfo.identification_status == 'PENDING'">
                                    <span>{{ i18n('global.menu.warning_id_not_verified') }}</span>
                                </div>
                            </div>
                            <div class="profile-menu-item" v-if="userInfo" @click="logout">
                                <div>{{ i18n('global.menu.btn_sign_off') }}</div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <div class="user" v-else>
                <div>
                    <a-button type="link" size="large" @click="onSignUpClick">{{ i18n('global.menu.btn_sign_up') }}</a-button>
                    <a-button type="primary" size="large" @click="onSignInClick">{{ i18n('global.menu.btn_sign_in') }}</a-button>
                </div>
            </div>
        </div>  
        <a-button class="nav-show-btn" type="text" shape="circle" @click="onMenuClick">
            <template #icon><MenuOutlined /></template>
        </a-button>
    </div>
    <transition name="slide-down" >
        <div class="mobile-nav" v-if="showMobileMenu">
            <div class="user" v-if="userInfo" @click="onDashBoardClick()">
                <div class="my-user-card" >
                    <div class="initial">{{userInfo.initial}}</div>
                    <div class="name">
                        <div class="display-name">{{userInfo.display_name}}</div>
                        <div class="email">{{userInfo.email}}</div>
                    </div>
                </div>
            </div>
            <a-menu class="mobile-nav-menu" v-model:selectedKeys="selectedKeys" :disabledOverflow="true" v-if="currentPath=='/'">
                <a-menu-item key="TRADE" :title="i18n('global.menu.buy_sell')" @click="onTradeClick">{{ i18n('global.menu.buy_sell') }}</a-menu-item>
                <a-menu-item title="Dashboard" @click="onItemClick('/dashboard')" v-if="currentPath=='/' && userInfo">Dashboard</a-menu-item>
                <a-menu-item v-for="menu in menus" :key="menu.path" :title="menu.name" @click="onItemClick(menu.path)">{{menu.name}}</a-menu-item>
            </a-menu>
            <a-menu
                class="mobile-nav-menu"
                :default-selected-keys="['DASHOARD']"
                :selectedKeys="selectedDashboardMenuKeys"
                v-if="userInfo"
                >
                <a-popconfirm
                    title="Are you sure to logout?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="logout"
                >
                    <a-menu-item class="side-nav-menu logout" >
                        <template #icon>
                            <LogoutOutlined />
                        </template>
                        <span>Logoff</span>
                    </a-menu-item>
                </a-popconfirm>
            </a-menu>
            <div class="user" v-if="!userInfo">
                <a-button type="link" size="large" @click="onSignUpClick">{{ i18n('global.menu.btn_sign_up') }}</a-button>
                <a-button type="primary" size="large" @click="onSignInClick">{{ i18n('global.menu.btn_sign_in') }}</a-button>
            </div>
        </div>
    </transition>
</a-layout-header>
</template>

<script setup lang="ts">
import { h, ref, reactive, computed, watch, onMounted } from "vue";
import { MenuOutlined, BellFilled, WalletOutlined, UserOutlined, BookOutlined, SwapOutlined, DownloadOutlined, UploadOutlined, LogoutOutlined } from "@ant-design/icons-vue";
import { useStore } from "../../store" ;
import { useRoute, useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'

const { t:i18n } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();
const showMobileMenu = ref<Boolean>(false);
const showProfileMenu = ref<Boolean>(false);

const selectedKeys = ref([]);
const userInfo = computed(() => store.getters.userInfo );

watch(showMobileMenu, (status) => {
    store.commit('setMobileMenuOpenStatus', status);
});
const menus = ref([
    { key: `CONTACT_US`, name: i18n('global.menu.contact_us'), path: `/contact_us` },
    { key: `FAQS`, name: i18n('global.menu.faq'), path: `/faqs` },
]);

const dashboardMenus = ref<Array<any>>([
    { key: 'DASHOARD', title: `Dashboard`, icon: WalletOutlined, path: `/dashboard` },
    { key: 'PROFILE', title: `My Profile`, icon: UserOutlined, path: `/dashboard` },
    { key: 'DEPOSIT', title: `Deposits`, icon: DownloadOutlined, path: `/dashboard/deposit` },
    { key: 'WITHDRAW', title: `Withdrawals`, icon: UploadOutlined, path: `/dashboard/withdraw` },
    { key: 'TRADE', title: i18n('global.menu.buy_sell'), icon: SwapOutlined, path: `/trade` },
    { key: 'REFERRAL', title: `Referral`, icon: BookOutlined, path: `/dashboard/referral` },
]);

onMounted(() => {
    checkMaintenance();
});

const currentPath = computed(() => {
    return route.path;
});

const isHome = computed(() => {
    return route.path == '/' 
    || route.path == `/session-expired` 
    || route.path == `/404` 
    || route.path == `/about_us`
    || route.path == `/contact_us`
    || route.path == `/features`
    || route.path == `/privacy`
    || route.path == `/term_of_use`
    || route.path == `/signupsuccess`
    || route.path == `/faqs`;
});

const isLogin = computed(() => {
    return route.path == '/signin' || route.path == '/signup' || route.path == `/forgotpassword` || route.path.startsWith(`/reset-password`) || route.path.startsWith(`/activate-account`);
});

const onMenuClick = () => {
    showMobileMenu.value = !showMobileMenu.value;
    
}
const onItemClick = (path = '/') => {
    router.push({ path });
    selectedKeys.value = [];
    showMobileMenu.value = false;
}
const onSignInClick = () => {
    router.push({ path: `/signin` });
    showMobileMenu.value = false;
}
const onSignUpClick = () => {
    router.push({ path: `/signup` });
    showMobileMenu.value = false;
}
const onDashBoardClick = () => {
    // router.push({ path: `/dashboard` });
    showMobileMenu.value = false;
    showProfileMenu.value = !showProfileMenu.value;
}
const onDashboardMenuClick = (path:string) => {
    router.push({ path });
    showMobileMenu.value = false;
}

const checkMaintenance = async () => {
    await store.dispatch('checkMaintenance');
}

const selectedDashboardMenuKeys = computed(() => {
    let key:Array<any> = [];
    for(let i = 0; i < dashboardMenus.value.length; i++ ) {
        if(currentPath.value.includes(dashboardMenus.value[i].path)) {
            key = [dashboardMenus.value[i].key]
        }
    }
    return key;
})
const logout = () => {
    router.replace(`/signin`);
    store.dispatch('setAccessToken');
}
const onProfileClick = () => {
    router.push({ path: `/dashboard/profile` });
}
const showNotificationIcon = computed(() => {
    if(!userInfo.value.identification_type) return true;
    if(userInfo.value.identification_status == 'PENDING') return true;
    return false;
});

const onTradeClick = () => {
    if(userInfo.value) {
        router.push({ 
            name: `MyTrade`, 
        });
    }
    else {
        router.push({ 
            name: 'SignIn', 
            query: { 
                redirect: 'MyTrade', 
            } 
        });
    }
}

</script>

<style lang="less">
.ant-layout-header.global-top-header {
    display: flex;
    height: auto;
    justify-content: center;
    z-index: 5;
    width: 100%;
    padding: 0;
    border-bottom: 2px solid #EFF1F3;
    &.home {
        .global-top-header-container {
            max-width: 100vw;
        }
    }
    
    .global-top-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        height: 113px;
        width: 100%;
        max-width: 1280px;
        background-color: #FFF;
        position: relative;
        transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        z-index: 7;
        padding: 0px 20px 0px 20px;
        @media screen and (max-width: 415px) {
            height: 80px;
        }
        .logo {
            display: flex;
            flex-wrap: wrap;
            color: @primary-color;
            font-weight: 800;
            align-items: center;
            justify-content: center;
            align-content: center;
            cursor: pointer;
            img {
                width: 40px;
                border-radius: 8px;
                margin: 8px;
                @media screen and (max-width: 415px) {
                    width: 34px;
                    margin: 4px;
                }
            }
            .name {
                font-size: 32px;
                @media screen and (max-width: 415px) {
                    font-size: 28px;
                }
            }
        }
        .nav {
            display: flex;
            font-size: 20px;
            line-height: 114px;
            @media screen and (max-width: 910px) {
                display: none;
            }
            @media screen and (max-width: 415px) {
                line-height: 80px;
            }
            .nav-menu {
                font-size: 20px;
                border-bottom: none;
            }
            .user {
                margin-left: 2em;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                cursor: pointer;
                min-width: 200px;
                .my-user-card {
                    display: flex;
                    line-height: 1em;
                    align-items: center;
                    justify-content: flex-start;
                    color:#333;
                    padding: 0.6em 1em;
                    width: 100%;
                    position: relative;
                    .initial {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background-color: @primary-color;
                    }
                    .name {
                        margin-left: 0.5em;
                        font-size: 20px;
                        .display-name {
                            line-height: 1.5em;
                        }
                        .email {
                            font-size: 14px;
                        }
                    }
                    .notification {
                        font-size: 0.8em;
                        background-color: rgb(174, 0, 0);
                        color: #FFF;
                        border-radius: 50%;
                        padding: 5px;
                        position: absolute;
                        right: 4px;
                        top: 4px;
                        opacity: 0.7;
                        transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    }
                    &:hover {
                        .notification {
                            opacity: 1;
                            transform: scale(1.1);
                        }
                    }
                }
                
                .profile-menu {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    background-color: #FFF;
                    border-radius: 0 0 8px8px;
                    box-shadow: 0px 1.66744px 16.6744px rgba(0, 0, 0, 0.1);
                    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    width: 100%;
                    .profile-menu-user-card-container {
                        height: 114px;
                        display: flex;
                        align-items: center;
                        @media screen and (max-width: 415px) {
                            height: 81px;
                        }
                    }
                    .profile-menu-items {
                        line-height: 1.8em;
                        padding: 1em 0em;
                        background-color: rgba(0,0,0,0.02);
                        .profile-menu-item {
                            padding: 0.5em 1em;
                            cursor: pointer;
                            transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                            display: flex;
                            flex-direction: column;
                            &:hover {
                                color: @primary-color;
                                background-color: rgba(0, 0, 0, 0.03);
                            }
                            .warning {
                                color: #ae0000;
                                font-size: 0.6em;
                                line-height: 1em;
                                font-style: italic;
                            }
                        }
                    }
                }
                &.show {
                    cursor: default;
                    .profile-menu {
                        opacity: 1;
                    }
                }
            }
        }
        .nav-show-btn {
            display: none;
            & > * {
                font-size: 30px;
            }
            @media screen and (max-width: 910px) {
                display: block;
            }
        }
    }
    .mobile-nav {
        width: 100%;
        display: none;
        padding: 32px 0px;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0 8px 20px -16px rgba(0,0,0,0.3) inset;
        height: calc(100vh - 114px);
        position: absolute;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        z-index: 100;
        top: 114px;
        @media screen and (max-width: 910px) {
            display: block;
        }
        @media screen and (max-width: 415px) {
            height: calc(100vh - 80px);
            top: 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 50px;
        }
        .mobile-nav-menu {
            font-size: 20px;
            border-bottom: none;
            border-right: none;
            background-color: transparent;
            text-align: center;
            .ant-menu-item {
                line-height: 60px;
                height: 60px;
                margin: 0;
                user-select: none;
                &:active {
                    background-color: rgb(67, 67, 67);
                    color: @primary-color
                }
            }
            .ant-menu-item-selected {
                background-color: #333;
            }
        }
        .user {
            display: flex;
            padding: 1em 0em;
            align-items: center;
            justify-content: center;
            align-content: center;
            @media screen and (max-width: 415px) {
                flex-direction: column-reverse;
            }
            .my-user-card {
                display: flex;
                line-height: 1em;
                align-items: center;
                justify-content: flex-start;
                border-radius: 8px;
                background-color: #eee;
                padding: 1em;
                color:#333;
                .initial {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background-color: @primary-color;
                    font-size: 1.3em;
                }
                .name {
                    margin-left: 0.5em;
                    font-size: 1.3em;
                    .display-name {
                        line-height: 1.5em;
                    }
                    .email {
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}
</style>