import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store";
import { createI18n } from 'vue-i18n';
import messages from "./i18n";

import "ant-design-vue/dist/antd.less";
import VueGtag from "vue-gtag-next";
import { message, Modal, notification } from 'ant-design-vue'

const app = createApp(App);

const i18n = createI18n({
    legacy: false,
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages,
})

app.config.globalProperties.$notification = notification;
app.config.globalProperties.$message = message;
app.config.globalProperties.$confirm = Modal.confirm;
app.config.globalProperties.$info = Modal.info;

app.use(VueGtag, {
    property: {
        id: "G-BMLVPRLSTC"
    }
});
app.use(router);
app.use(i18n);
app.use(store);
app.mount('#app');