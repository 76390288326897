<template>
    <div class="side-nav" :class="{home:isHome||isLogin, collapsed}">
        <div class="logo" @click="onHomeClick">
            <img src="../../assets/logo.svg" alt="">
            <div class="name">OTCPro</div>
        </div>
        <a-menu
        class="side-nav-menus"
        :default-selected-keys="['DASHOARD']"
        :openKeys="openKeys"
        :selectedKeys="selectedKeys"
        mode="inline"
        >
            <template v-for="menu in menus">
                <a-sub-menu :key="menu.key+'1'" v-if="menu.sub_menus.length>0" class="side-nav-menu" @titleClick="onMenuClick(menu.path||'/kyc')">
                    <template #title ><div>{{menu.title}}</div></template>
                    <a-menu-item  v-for="sub in menu.sub_menus" :key="sub.key" @click="onMenuClick(sub.path||'/kyc')" :disabled="!sub.path">{{sub.title}}</a-menu-item>
                </a-sub-menu>
                <a-menu-item v-else :key="menu.key" class="side-nav-menu" @click="onMenuClick(menu.path||'/kyc')" :disabled="!menu.path" >
                    <!-- <template #icon>
                        <component :is="menu.icon" />
                    </template> -->
                    {{menu.title}}
                    
                </a-menu-item>
            </template>
            <!-- <a-popconfirm
                title="Are you sure to logout?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="logout"
            >
                <a-menu-item class="side-nav-menu logout" >
                    <template #icon>
                        <LogoutOutlined />
                    </template>
                    <span>Logoff</span>
                </a-menu-item>
            </a-popconfirm> -->
        </a-menu>
    </div>
</template>

<script setup lang="ts">
import { h, ref, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "../../store" ;
// import Login from "./Login.vue";
import type { MenuProps } from 'ant-design-vue';
import { AppstoreOutlined, UserOutlined, BookOutlined, WalletOutlined, SwapOutlined, DownloadOutlined, UploadOutlined, HomeOutlined, CustomerServiceOutlined, LockOutlined, LogoutOutlined } from "@ant-design/icons-vue";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
const store = useStore();
const userInfo = computed(() => store.getters.userInfo );
const accessToken = computed(() => store.getters.accessToken );
// const openKeys = ref<string[]>([]);

const props = defineProps({
    collapsed: {type: Boolean, default: false},
})

const currentPath = computed(() => {
    return route.path;
});
const menus = computed(() => {
    if(userInfo.value && userInfo.value.identification_status == 'VERIFIED') {
        let m = [
            { key: 'DASHOARD', title: `Dashboard`, icon: WalletOutlined, path: `/dashboard`, sub_menus:[] },
            { key: 'DEPOSIT', title: `Deposits`, icon: DownloadOutlined, path: `/dashboard/deposit`, sub_menus:[] },
            { key: 'WITHDRAW', title: `Withdrawals`, icon: UploadOutlined, path: `/dashboard/withdraw`, sub_menus: [
                { key: 'WITHDRAW.RECENT', title: 'Make Withdrawals', path: `/dashboard/withdraw` },
                { key: 'WITHDRAW.TOKEN_ADDRESS', title: 'Manage Crypto Addresses', path: `/dashboard/withdraw/token` },
                { key: 'WITHDRAW.BANK_ACCOUNT', title: 'Manage Bank Accounts', path: `/dashboard/withdraw/bank` },
            ] },
            { key: 'TRADE', title: `Buy/Sell`, icon: SwapOutlined, path: `/dashboard/trade`, sub_menus:[] },
            { key: 'HISTORY', title: `History`, icon: SwapOutlined, path: `/dashboard/history`, sub_menus:[] },
            { key: 'REFERRAL', title: `Referral`, icon: BookOutlined, path: `/dashboard/referral`, sub_menus:[] },
            { key: 'SECURITY', title: `Security`, icon: LockOutlined, path: `/dashboard/security`, sub_menus:[
                { key: 'SECURITY.UPDATE_PWD', title: 'Update Password', path: `/dashboard/security` },
                { key: 'SECURITY.UPDATE_2FA', title: 'Update 2FA', path: `/dashboard/security/2fa` },
                
            ] },
            { key: 'SUPPORT', title: `Support`, icon: CustomerServiceOutlined, path: `/dashboard/support`, sub_menus:[] },
        ];
        if(userInfo.value.dev_key_enabled == 1) {
            m[6].sub_menus.push({ key: 'SECURITY.UPDATE_DEV_API', title: 'Developer API', path: `/dashboard/security/developer` });
        }
        return m;
    }
    return [
        { key: 'KYC', title: `Complete Kyc`, icon: UserOutlined, path: `/dashboard/kyc`, sub_menus:[] },
        { key: 'DASHOARD', title: `Dashboard`, icon: WalletOutlined, sub_menus:[] },
        { key: 'DEPOSIT', title: `Deposits`, icon: DownloadOutlined, sub_menus:[] },
        { key: 'WITHDRAW', title: `Withdrawals`, icon: UploadOutlined, sub_menus:[]},
        { key: 'TRADE', title: `Buy/Sell`, icon: SwapOutlined, sub_menus:[] },
        { key: 'REFERRAL', title: `Referral`, icon: BookOutlined, sub_menus:[] },
        { key: 'SECURITY', title: `Security`, icon: LockOutlined, path: `/dashboard/security`, sub_menus:[] },
        { key: 'SUPPORT', title: `Support`, icon: CustomerServiceOutlined, path: `/dashboard/support`, sub_menus:[] },
    ]
})
const displayName = computed(() => {
    if(userInfo.value) {
        if(props.collapsed) {
            return userInfo.value.initial;
        }
        return userInfo.value.display_name;
    }
    return '';
});

const selectedKeys = computed(() => {
    let key:Array<any> = [];
    for(let i = 0; i < menus.value.length; i++ ) {
        let path = menus.value[i].path;
        if(path && currentPath.value.includes(path)) {
            key = [menus.value[i].key]
        }
        if(menus.value[i].sub_menus.length > 0) {
            for(let j = 0; j < menus.value[i].sub_menus.length; j++ ) {
                path = menus.value[i].sub_menus[j].path;
                if(path && currentPath.value.includes(path)) {
                    key = [menus.value[i].sub_menus[j].key]
                }
            }
        }
    }
    return key;
});


const isHome = computed(() => {
    return route.path == '/' || route.path == `/session-expired` || route.path == `/404`;
});

const isLogin = computed(() => {
    return route.path == '/signin' || route.path == '/signup' || route.path == `/forgotpassword`;
});

const openKeys = computed(() => {
    if(selectedKeys.value.length > 0) {
        return [selectedKeys.value[0].split('.')[0] + '1'];
    }
    return []
})

const onMenuClick = async (path:string) => {
    await checkLoginStatus();
    router.push({ path });
    // openKeys.value = [];
}
const titleClick = (e: Event) => {
};
const logout = () => {
    router.replace(`/signin`);
    store.dispatch('setAccessToken');
}
const onHomeClick = () => {
    router.push({ path: '/' });
}
const checkLoginStatus = async () => {
    await store.dispatch('checkLoginStatus');
}
</script>


<style lang="less">
// .ant-layout-header {
    .side-nav {
        display: flex;
        flex-direction: column;
        align-content: flex-end;
        justify-content: flex-start;
        align-items: stretch;
        user-select: none;
        color: #333;
        flex-wrap: nowrap;
        height: 100vh;
        border-right: 1px solid #ccc;
        .logo {
            display: flex;
            flex-wrap: wrap;
            color: @primary-color;
            font-weight: 800;
            align-items: center;
            justify-content: flex-start;
            align-content: center;
            padding: 2em 0em;
            padding-left: 24px;
            cursor: pointer;
            img {
                width: 50px;
                border-radius: 8px;
                margin: 8px;
            }
            .name {
                font-size: 36px;
            }
        }
        .ant-menu-inline {
            border-right: none;
            .ant-menu-item::after {
                border:none;
            }
        }

        .header-logo {
            color: #ffe477;
            padding: 2em 1em;
            display: flex;
            align-content: flex-end;
            justify-content: flex-end;
            align-items: flex-end;
            font-size: 18px;
            flex-direction: column;
            cursor: pointer;
            transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            &:hover {
                color: #fcd63f;
            }
            .otc {
                line-height: 0.6em;
                font-size: 2em;
            }
            .pro {
                line-height: 0.8em;
                font-size: 3em;
                font-weight: 600;
            }
        }
        .profile-container {
            margin-top: 1em;
            display: flex;
            flex-direction: column;
            font-size: 1em;
            line-height: 1em;
            padding: 1em 0px;
            width: 100%;
            border-top: 1px solid #ffe477;
            transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            text-align: right;
            cursor: pointer;
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
                padding: 1em;
            }
            &:active {
                background-color: rgba(255, 255, 255, 0.2);
                padding: 1em;
            }
        }
        .ant-menu-item-group {
            width: 100%;
            margin-bottom: 16px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            .ant-menu-item-group-title {
                text-align: right;
                color: rgba(255, 255, 255, 0.5);
            }
        }

        .side-nav-menus {
            display: flex;
            height:100%;
            flex-direction: column;
            align-items: center;
            align-content: center;
            flex-wrap: nowrap;
            background: transparent !important;
            border-right: none;
            // padding: 1em 0em;
            .side-nav-menu {
                height: 40px;
                // border-right: 1px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;
                transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-start;
                font-size: 20px;
                align-items: center;
                margin: 8px 0em !important;
                border-right: 8px solid transparent;
                width: 100%;
                &:hover {
                    color: #00B77E;
                }
                &.ant-menu-submenu {
                    display: flex;
                    flex-direction: column;
                    min-height: 40px;
                    height: auto;
                    border-right: none;
                    .ant-menu-sub {
                        background-color: transparent;
                        .ant-menu-item {
                            height: 30px;
                            &:hover {
                                color: #00B77E;
                            }
                            &.ant-menu-item-selected {
                                background-color: transparent;
                                color: #00B77E;
                            }
                        }
                    }
                }
                &.ant-menu-item-selected {
                    background-color: #fff;
                    color: #00B77E;
                    border-right: 8px solid #00B77E;
                }
                &.ant-menu-submenu-selected {
                    color: #00B77E;
                    .ant-menu-submenu-title {
                        background-color: #fff;
                        color: #00B77E;
                        border-right: 8px solid #00B77E;
                    }
                }
                .ant-menu-item-icon {
                    font-size: 22px;
                }
                .ant-menu-title-content {
                    text-align: left;
                    padding: 0 10px;
                }
                .ant-menu-submenu-arrow {
                    display: none;
                }
                &.logout {
                    margin-top: 3em;
                    font-size: 16px;
                    &:hover {
                        color: @primary-color;
                    }
                }
                // &:last-child {
                //     border-right: 1px solid rgba(0, 0, 0, 0.1);
                // }
            }
        }
        &.home {
            flex-wrap: wrap;
            .header-logo {
                flex: 1;
                font-size: 48px;
                padding: 1em;
            }
            .profile-container {
                font-size: 0.7em;
                border-top: none;
                width: auto;
            }
            .side-nav-menus {
                flex: 1;
                padding: 1em;
                display: flex;
                font-size: 48px;
                align-content: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                overflow: hidden;
                transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                background-color: transparent;
                .side-nav-menu {
                    font-size: 32px;
                    width: 100%;
                    .ant-menu-item-icon {
                        font-size: 32px;
                    }
                }
                &.hide {
                    opacity: 0;
                    height: 0px;
                }
            }
        }
        &.collapsed {
            .profile-container {
                font-size: 23px;
                text-align: center;
                &:hover {
                    padding: 1em 0px;
                }
                &:active {
                    padding: 1em 0px;
                }
            }
            .side-nav-menus {
                width: 100% !important;
                .side-nav-menu {
                    display: flex;
                    padding: 0;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    .ant-menu-item-icon {
                        font-size: 22px;
                    }
                    .ant-menu-title-content {
                        display: none;
                    }
                }
                .ant-menu-item-group {
                    width: 100%;
                    .ant-menu-item-group-title {
                        height: 2px;
                        overflow: hidden;
                        color: transparent;
                        border-top: 1px solid rgba(255, 255, 255, 0.1);
                    }
                    .ant-menu-item-group-list {
                        .side-nav-menu {
                            display: flex;
                            padding: 0;
                            width: 100%;
                            align-items: center;
                            justify-content: center;
                            align-content: center;
                            .ant-menu-item-icon {
                                font-size: 22px;
                            }
                            .ant-menu-title-content {
                                display: none;
                            }
                        }
                    }
                }
            }
            
        }
    }
// }
</style>